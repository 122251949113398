import React from "react";

import "./banner.scss";

const Banner = (props) => {
  const { 
    background,
    mainText,
    secondaryText,
    centeredText
  } = props;

  const _secondaryText = secondaryText 
    ? JSON.parse(secondaryText.raw).content[0].content[0].value 
    : "";

  return (
    <section className="m-banner">
      <div className={`m-banner__bg ${centeredText ? "" : "m-banner__bg--right"}`}>
        <img alt="Fondo banner" src={background.file.url}/>
      </div>
      <div className="m-banner__content">
        <div 
          className={`${
            centeredText 
              ? "m-banner__content__text--centered"
              : "m-banner__content__text"
            }`
          }
        >
          <h3 
            className={`${
              centeredText 
                ? "m-banner__content__text--centered__title"
                : "m-banner__content__text__title"
              }`
            }
          >
            {mainText}
          </h3>
          <h4 
            className={`${
              centeredText 
                ? "m-banner__content__text--centered__desc"
                : "m-banner__content__text__desc"
              }`
            }
          >
            {_secondaryText}
          </h4>
        </div>
      </div>
    </section>
  )
}

export default Banner;