import React from "react";
import Layout from "../components/organisms/layout/layout";
import ProductsTemplate from "../components/templates/products-template/productsTemplate";

const Products = () => {
  return (
    <Layout title="Dicar | Productos">
      <ProductsTemplate />
    </Layout>
  )
}

export default Products;