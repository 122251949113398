import React from "react";

import ProductCard from "../../atoms/product-card/productCard";
import NewsProducts from "../../organisms/news-products/newsProducts";

import "./categoryDetailTemplate.scss";

const CategoryDetailTemplate = (props) => {
  const {
    title,
    description,
    products,
    newsProducts,
    showSideNews = false
  } = props;

  const getFormattedDate = (date) => {
    const _date = new Date(date);
    return `${_date.getDate()}/${_date.getMonth()}/${_date.getFullYear()}`;
  }

  const desc = description ? JSON.parse(description.raw) : "";

  return (
    <>
    <section className="t-category-detail">
      <div className="t-category-detail__page">
        <h1 className="t-category-detail__page__title">
          {title}
        </h1>
        {description && 
          <p className="t-category-detail__page__desc">
            {desc.content[0].content[0].value}
          </p>
        }
        <div className="t-category-detail__page__products">
          {products.map((product) => (
            <div
              key={product.productName}
              className="t-category-detail__page__products__product"
            >
              <ProductCard {...product} />
            </div>
          ))}
        </div>
      </div>
      {showSideNews &&
        <div className="t-category-detail__side-news">
          <h2 className="t-category-detail__side-news__title">
            {newsProducts.textTitle}
          </h2>
          <div className="t-category-detail__side-news__products">
            {newsProducts.products.slice(0, 5).map((product) => (
              <div
                key={product.productName}
                className="t-category-detail__side-news__products__product"
              >
                <div className="t-category-detail__side-news__products__product__text">
                  <a
                    className="t-category-detail__side-news__products__product__text__name"
                    href={product.href}
                  >
                    {product.productName}
                  </a>
                  <p className="t-category-detail__side-news__products__product__text__date">
                    {getFormattedDate(product.createdAt)}
                  </p>
                </div>
                <a
                  className="t-category-detail__side-news__products__product__image"
                  href={product.href}
                >
                  <img
                    src={product.productImage.file.url}
                    alt={`${product.productName}`}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      }
    </section>
    <NewsProducts {...newsProducts} />
    </>
  )
}

export default CategoryDetailTemplate;