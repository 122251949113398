import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import BannerSlider from "../../organisms/banner-slider/bannerSlider";
import ProductsServices from "../../molecules/products-services/productsServices";
import CategoryDetailTemplate from "../category-detail-template/categoryDetailTemplate";

const ProductsTemplate = () => {
  const data = useStaticQuery(graphql`
		query QUERY_PRODUCTS {
			allContentfulPaginaProductos {
        nodes {
          description {
            raw
          }
          textTitle
          servicesSection {
            services {
              href
              name
              image {
                file {
                  url
                }
              }
            }
          }
          prominents {
            items {
              mainText
              secondaryText {
                raw
              }
              centeredText
              background {
                file {
                  fileName
                  url
                }
              }
            }
            page
          }
          products {
            href
            productName
            productImage {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulNovedadesProductos {
        nodes {
          textTitle
          products {
            createdAt
            href
            productImage {
              file {
                url
              }
            }
            productName
          }
        }
      }
		}
  `);

  const title = data.allContentfulPaginaProductos.nodes[0].textTitle;
  const description = data.allContentfulPaginaProductos.nodes[0].description;
  const bannerData = data.allContentfulPaginaProductos.nodes[0].prominents.items;
  const servicesData = data.allContentfulPaginaProductos.nodes[0].servicesSection.services;
  const productsData = data.allContentfulPaginaProductos.nodes[0].products;
  const newsProductsData = data.allContentfulNovedadesProductos.nodes[0];

  return (
    <div className="t-products">
      <BannerSlider items={bannerData} />
      <ProductsServices services={servicesData} />
      <CategoryDetailTemplate
        title={title}
        description={description}
        products={productsData}
        newsProducts={newsProductsData}
        showSideNews={true}
      />
    </div>
  )
}

export default ProductsTemplate;