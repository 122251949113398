import React from "react";
import Anchor from "../../atoms/anchor/anchor";
import "./productsServices.scss";

const ProductsServices = (props) => {
  const { services } = props;

  return (
    <section className="m-prod-services">
      <ul className="m-prod-services__container">
        {services.map((service) => (
          <li key={service.name} className="m-prod-services__container__service">
            <Anchor
              className="m-prod-services__container__service__image"
              href={service.href}
            >
              <img
                src={service.image.file.url}
                alt={`Servicio ${service.name}`}
              />
            </Anchor>
            <Anchor
              className="m-prod-services__container__service__name"
              href={service.href}
            >
              {service.name}
            </Anchor>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ProductsServices;