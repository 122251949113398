import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Banner from "../../molecules/banner/banner";

import "./bannerSlider.scss";

const BannerSlider = (props) => {
  const { items } = props;

  const [sliderIndex, setSliderIndex] = useState(0);
  const [slider, setSlider] = useState();

  const goToNext = () => slider.slickGoTo(sliderIndex + 1);

  const goToBack = () => slider.slickGoTo(sliderIndex - 1);

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHober: true,
    pauseOnDotsHover: true,
    dotsClass: "slick-dots o-banner-slider__slider__dots",
    beforeChange: (_, next) => setSliderIndex(next)
  };

  return (
    <section className="o-banner-slider">
      <Slider 
        {...sliderSettings}
        ref={_slider => setSlider(_slider)} 
        className="o-banner-slider__slider" 
      >
        {items.map(item => (
          <Banner key={item.mainText} {...item} />
        ))}
      </Slider>

      <div 
        className="o-banner-slider__arrow-content o-banner-slider__arrow-content__left" 
        onClick={() => goToBack()}
        role="button"
      >
        <div className="o-banner-slider__arrow-content__arrow icon-chevron-left" />
      </div>

      <div 
        className="o-banner-slider__arrow-content o-banner-slider__arrow-content__right" 
        onClick={() => goToNext()}
        role="button"
      >
        <div className="o-banner-slider__arrow-content__arrow icon-chevron-right" />
      </div>
    </section>
  )
}

export default BannerSlider;