import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewProductCard from "../../atoms/new-product-card/newProductCard";

import "./newsProducts.scss";

const NewsProducts = (props) => {
  const { textTitle, products } = props;

  const [sliderIndex, setSliderIndex] = useState(0);
  const [slider, setSlider] = useState();

  const goToNext = () => slider.slickGoTo(sliderIndex + 1);

  const goToBack = () => slider.slickGoTo(sliderIndex - 1);

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (_, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 801,
        settings: {
          dots: true,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 602,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <section className="o-news-products">
      <h2 className="o-news-products__title">
        {textTitle}
      </h2>
      <Slider
        {...sliderSettings}
        ref={_slider => setSlider(_slider)}
        className="o-news-products__slider"
      >
        {products.map((product) => (
          <div key={product.productName} className="o-news-products__slider__item">
            <NewProductCard {...product} greyBg={true} />
          </div>
        ))}
      </Slider>
      <div
        className="o-news-products__arrow-content o-news-products__arrow-content__left"
        onClick={() => goToBack()}
        role="button"
      >
        <div className="o-news-products__arrow-content__arrow icon-chevron-left" />
      </div>
      <div
        className="o-news-products__arrow-content o-news-products__arrow-content__right"
        onClick={() => goToNext()}
        role="button"
      >
        <div className="o-news-products__arrow-content__arrow icon-chevron-right" />
      </div>
    </section>
  )
}

export default NewsProducts;