import React from "react";
import "./productCard.scss";

const ProductCard = (props) => {
  const { productName, productImage, href} = props;

  return (
    <div className="a-product-card">
      <a
        className="a-product-card__title"
        href={href}
      >
        <p
          className="a-product-card__title__name"
        >
          {productName}
        </p>
        <i className="icon-chevron-right" />
      </a>
      <a
        className="a-product-card__image"
        href={href}
      >
        <img
          src={productImage.file.url}
          alt={productName}
        />
      </a>
    </div>
  )
}

export default ProductCard;